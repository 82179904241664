import axiosInstance from "./axiosInstance";

// user list
export const UserListApi = async (data) => {
  const response = await axiosInstance.get("/user", { params: data });
  return response.data;
};

// user edit
export const UserEditApi = async (data) => {
  const response = await axiosInstance.put(`/user/${data._id}`, data);
  return response.data;
};

// user get details
export const UserDetailApi = async (id) => {
  const response = await axiosInstance.get(`/user/${id}`);
  return response.data;
};
